<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="ES主机" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-input v-model="form.es_hosts" placeholder="请输入ES主机..." />
          </a-form-model-item>
        </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="用户名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="user">
              <a-input v-model="form.user" placeholder="请输入用户名..." />
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="ES主机名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts_name">
              <a-input v-model="form.es_hosts_name" placeholder="请输入ES主机名称..." />
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="ES索引名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="index_name">
              <a-input v-model="form.index_name" placeholder="请输入ES索引名称..." />
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="ES状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="is_delete">
              <a-select v-model="form.is_delete" placeholder="请选择ES状态..." :options='is_delete'>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">

              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加ES
      </a-button>
    </div>
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.es_hosts_id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <template slot="is_delete" slot-scope="data,record">
          <a-tag :color="record.is_delete=='0'?'green':'red'">
              {{record.is_delete|statusFilter}}
          </a-tag>
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>
                <a-button size="small" type="primary"  @click="onGetESCategory(data)">获取分类</a-button>
                <a-button size="small" type="primary"  @click="onEdit(data)">编辑</a-button>

                <a-popconfirm v-if="data.is_delete=='0'" title="是否禁用此ES?" @confirm="() => onDisable(data)">
                  <a-button size="small" type="danger">禁用</a-button type="primary" >
                </a-popconfirm>
                <a-popconfirm v-else title="是否启用此ES?" @confirm="() => onEnable(data)">
                  <a-button size="small" type="primary" class="greenBtn">启用</a-button type="primary">
                </a-popconfirm>
                <a-button size="small" type="primary"  @click="statistics(data.es_hosts_id)">流量统计</a-button>

              </span>

        </template>
      </a-table>
    </div>

  <!-- 修改帐号信息模态框 -->
  <a-modal

      :title="title"
      :visible="editModalVisible"
      :confirm-loading="confirmLoading"
      @ok="save"
      @cancel="cancel"
    >
      <!-- 编辑 -->
      <a-form-model
        ref="editRef"
        :model="editForm"
        :rules="editFormRules"
        :label-col="{span:7}"
        :wrapper-col="{span:17}"
      >
      <a-form-model-item label="ES主机" prop="es_hosts">
        <a-input v-model="editForm.es_hosts" placeholder="请输入ES主机..."/>
      </a-form-model-item>
        <a-form-model-item label="ES主机名称" prop="es_hosts_name">
          <a-input v-model="editForm.es_hosts_name" placeholder="请输入ES主机名称..."/>
        </a-form-model-item>
        <a-form-model-item label="索引名称" prop="index_name">
          <a-input v-model="editForm.index_name" placeholder="请输入索引名称..."/>
        </a-form-model-item>
        <a-form-model-item label="用户名" prop="user">
          <a-input v-model="editForm.user" placeholder="请输入用户名..."/>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input v-model="editForm.password" placeholder="请输入密码..."/>
        </a-form-model-item>
        <a-form-model-item label="语言" prop="language_id">
          <a-select v-model.Number="editForm.language_id" placeholder="请选择语言..." :options='languagesList'>

          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="is_delete" v-if="title=='编辑ES'">
          <a-select v-model.Number="editForm.is_delete" placeholder="请选择ES状态..." :options='is_delete_edit'>

          </a-select>
        </a-form-model-item>
      </a-form-model>
  </a-modal>

  </div>
</template>
<script>
import {getEs_hostList,editEs_host,addEs_host,deleteEs_host,getEs_hostGetESCategory} from "@/axios/es_host.js"
import {getAllLanguagesList} from "@/axios/languages.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const columns = [//列描述
  {
    align:'center',
    title: 'ES主机ID',
    dataIndex: 'es_hosts_id',
  },
  {
    align:'center',
    title: 'ES主机',
    dataIndex: 'es_hosts',
  },

  {
    align:'center',
    title: 'ES主机名称',
    dataIndex: 'es_hosts_name',
  },
  {
    align:'center',
    title: 'ES主机索引名称',
    dataIndex: 'index_name',
  },
  {
    align:'center',
    title: '目录总数',
    dataIndex: 'category_total',
  },
  {
    align:'center',
    title: '语言',
    dataIndex: 'language_name',
  },

  {
    align:'center',
    title: '用户名',
    dataIndex: 'user',
  },
  {
    align:'center',
    title: '密码',
    dataIndex: 'password',
  },

  {
    align:'center',
    title: '状态',
    dataIndex: 'is_delete',
    scopedSlots: { customRender: 'is_delete', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        0: '启用',
        1: '禁用',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      is_delete_edit: [
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],
      is_delete: [
        {
            value: -1,
            label: '全部'
        },
        {
            value: 0,
            label: '启用'
        },
        {
            value: 1,
            label: '禁用'
        },
      ],
      languagesList:[],
      form: {
        page_size: 10,
        page:1,
        es_hosts_name: '',
        is_delete:0,
        user:"",
        index_name:'',
        es_hosts:'',
        // iso2:''
      },

      columns,//列描述

      confirmLoading:false,//编辑模态框
      editModalVisible:false,
      editForm:{
        es_hosts:'',
        es_hosts_name:'',
        user:'',
        language_id:'',
        password:'',
        index_name:'',
        is_delete:-1,
      },
      editFormRules: {
        es_hosts: [
          { required: true, message: '请输入ES主机', trigger: 'blur' },
        ],
        es_hosts_name: [
          { required: true, message: '请输入ES主机名称', trigger: 'blur' },
        ],
        // user: [
        //   { required: true, message: '请输入用户名', trigger: 'blur' },
        // ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        // ],

        language_id: [
          { required: true, message: '请选择语言', trigger: 'blur' },
        ],
        index_name: [
          { required: true, message: '请输入索引名称', trigger: 'blur' },
        ],
        is_delete: [
          { required: true, message: '请选择用户状态', trigger: 'change' },
        ],
      },

      title:'编辑ES',
      // languagesList:[]
    }

  },
  mounted() {
    let that=this
    this.fetch();
    getAllLanguagesList().then((data)=>{
      that.languagesList=data.data.map((item)=>{
        return{
          value:item.language_id,
          label:item.language_name
        }
      })
      console.log(that.languagesList,'语言列表')
    });
  },
  methods:{

      async fetch (params = {}) {
        this.loading = true;
        let data=await getEs_hostList({...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
      },

      //添加用户数据
      onAdd(){
        this.editModalVisible=true
        this.title='添加ES'
      },

      //禁用ES
      async onDisable(data){
        data.is_delete=1
        let resule=await editEs_host({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //启用ES
      async onEnable(data){
        data.is_delete=0
        console.log(data,'data')
        let resule=await editEs_host({...data});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
            this.fetch()
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      async onGetESCategory(data){
        let {es_hosts_id}=data
        let resule=await getEs_hostGetESCategory({es_hosts_id:es_hosts_id});
          if(resule.status==1){
            this.$message.success(resule.errmsg);
          }else{
            this.$message.error(resule.errmsg);
        }
      },
      //编辑保存用户数据
      onEdit(data){
        this.title='编辑ES'
        this.editModalVisible=true
        this.$nextTick(() => { // 注意看这里
          this.editForm={...data}
        });


        console.log(this.editForm,'data')

      },
      save(){
        this.$refs.editRef.validate( async valid => {
        if (valid) {
          this.confirmLoading = true;
          let resule
          if(this.title=='添加ES'){

            const {is_delete,...rest} = this.editForm;
            resule=await addEs_host(rest);
          }else{
            resule=await editEs_host({...this.editForm});
          }

          this.confirmLoading = false;
          if(resule.status==1){
            console.log(resule)
            this.$message.success(resule.errmsg);
            this.editModalVisible = false;
            this.$refs.editRef.resetFields();
            this.fetch();
          }else{
            this.$message.error(resule.errmsg);
          }

        } else {
          return false;
        }
      });


      },
      cancel(){
        this.$refs.editRef.resetFields();
        this.editModalVisible = false;
      },
    statistics(id){
      this.$router.push({path:'/source',query:{
          es_id:id,
        }})
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
</style>
